/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getAccessToken } from "@lib/shared/cookies";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

interface FormSubmitApiBody {
    fields: { codeName: string; value: string }[];
    leadId: string | null;
    jornayaId: string | undefined;
    url?: string;
    submit: boolean;
    domainFormId: number | null;
    otpCode: string | undefined;
    fetchBuyers?: boolean;
    language: string;
    meta?: {
        [x: string]: string | number | boolean;
    };
    isPiiCompletionForm?: boolean;
    token?: string;
    buyerIds: (number | string)[];
    consentLanguage: string;
    isOneToOneConsent?: boolean;
    forcedBuyersIds?: number[] | null;
    excludedBuyers?: number[] | null;
}
export interface SubmitLeadFormResponse {
    success: boolean;
    data: {
        leadId: string | null;
        operationId?: string;
        otpRequired: boolean;
        otpTime: number | undefined;
        results?:
            | undefined
            | {
                  status: "rejected";
                  codeName: string;
                  reason: string;
              }[];
        enqueued?: boolean;
    } | null;
}

export const submitLeadForm = async (
    data: Partial<FormSubmitApiBody>,
): Promise<{
    data: SubmitLeadFormResponse["data"] | null;
    error: Error | null;
}> => {
    const accessToken = getAccessToken();

    const {
        fields,
        leadId,
        jornayaId,
        url,
        submit,
        domainFormId,
        otpCode,
        language,
        meta,
        fetchBuyers,
        forcedBuyersIds,
        excludedBuyers,
    } = data;

    const trustedFormCertificateUrl: string =
        //@ts-ignore
        document.getElementById("TrustedFormCertUrl_0")?.value;

    const config: AxiosRequestConfig = {
        method: "post",
        url: `/api/submitLeadForm`,
        data: {
            fields,
            leadId: leadId ?? undefined,
            jornayaId: jornayaId ? jornayaId : undefined,
            url: url ?? window.location.href,
            submit,
            domainFormId: domainFormId ?? undefined,
            trustedFormCertificateUrl: trustedFormCertificateUrl
                ? trustedFormCertificateUrl
                : undefined,
            otpCode,
            language,
            meta,
            fetchBuyers,
            forcedBuyersIds,
            isPiiCompletionForm: data.isPiiCompletionForm ?? false,
            token: data.isPiiCompletionForm ? data.token : undefined,
            buyerIds: data.buyerIds,
            consentLanguage: data.consentLanguage,
            isOneToOneConsent: data.isOneToOneConsent ?? false,
            excludedBuyers,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken as string}`,
        },
    };

    const { data: submitLeadFormResponse, error } =
        await apiHandler<SubmitLeadFormResponse>(() => HTTP.client(config));

    return {
        data: submitLeadFormResponse?.data ?? null,
        error,
    };
};
