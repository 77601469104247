/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
    Category,
    Image,
    ListingItem,
    Meta,
    ServerRequest,
    TextBlock,
} from "@lib/shared/types";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosError, AxiosRequestConfig } from "axios";
import { Page, TemplateConfig } from "@page-builder/ts/types";
import { HandledPreDefinedAnswerSummaryConfigSlugs } from "@components/shared/form/answerSummary/preDefinedConfigs";
import { AnswerSummaryConfigs } from "@components/shared/form/formOld";
export interface DomainInfoResponse {
    data: DomainInfo | null;
    success: boolean;
}

interface ProxiedNumber {
    phoneNumber: string;
    categorySlug: string;
    category: string;
    categoryId: number;
    ts: string;
}
export interface HotTortsItem {
    icon?: string;
    name?: string;
    brief?: string;
    criteria?: string;
    slug?: string;
    headline?: string;
    "sub-headline"?: string;
    "action-button-headline"?: string;
    "form-headline"?: string;
}

export interface TeamMember {
    name: string;
    image: string;
    position: string;
}
export interface DomainConfig {
    showSmsTCPA?: boolean;
    sellingPoint?: string;
    goToThankYouPage?: boolean;
    sellingPointsSteps?: "FIRST_STEP" | "ALL_STEPS" | undefined;
    dynamicThankYouFields?: string[];
    withSideWidget?: boolean;
    popupStyle?: {
        actionBtnBgColor?: string;
        actionBtnColor?: string;
        iconColor?: string;
    };
    withForm?: boolean;
    formSideContent?: string;
    pages?: {
        [key: string]: Page;
    };
    header?: {
        variables?: {
            [key: string]: string;
        };
    };
    footer?: {
        variables?: {
            [key: string]: string;
        };
    };
    countryCodeColors?: {
        textColor?: string;
        clickHereColor?: string;
    };
    bannerBgColor?: string;
    bannerTextColor?: string;
    sliderLogos?: { [key: string]: { path: string; slug: string }[] };
    leadCapture?: boolean;
    useBingEnhancedConversion?: boolean;
    primaryColor?: string;
    stickyButtonTextColor?: string;
    stickyButtonBgColor?: string;
    offersPage?: {
        heroBg?: string;
        formBg?: string;
        insuranceListingTitle?: string;
    };
    smsTCPALinkColor?: string;
    headerBackgroundColor?: string;
    sloganTextColor?: string;
    stickyPulseColor?: string;
    css?: string;
    answerSummaryConfigs?: AnswerSummaryConfigs;
    preDefinedAnswerSummaryConfigs?: HandledPreDefinedAnswerSummaryConfigSlugs;
    header_phone_number?: string;
    team: {
        all: TeamMember[];
        main: TeamMember;
        header: string;
        paragraph_1: string;
        paragraph_2: string;
    };
    hot_torts: HotTortsItem[];
    showFormInsideOffersModal?: boolean;
    listSlug?: string;
    hideLegalNotice?: boolean;
    hideDoNotSell?: boolean;
}

export interface DomainInfo {
    id: number;
    name: string;
    niceName: string;
    title: string;
    slogan: string;
    type: "clicks" | "lead" | "affiliate" | "informational";
    description: string;
    clickyCodeId: string | null;
    analyticsCodeId: string | null;
    gtmId: string | null | undefined;
    favicon: Image | null;
    isInspectorEnabled: boolean;
    categories: Category[];
    logo: Image | null;
    secondaryLogo: Image | null;
    enableJornayaId: boolean;
    enableTrustedForm: boolean;
    twitterPixel: string;
    metatags: Meta[];
    showForm: boolean;
    listings: [
        {
            id: number;
            name: string;
            slug: string;
            isComparison: boolean;
            topOffer: null;
            icon: Image | null;
        },
    ];
    defaultOffers: ListingItem[];
    proxiedNumbers: ProxiedNumber[];
    recaptchaConfig: {
        key: string;
        secret: string;
    };
    advertisingNetworkTrackingSettings: {
        pixelId: string;
        network?: string;
    };
    config?: Partial<DomainConfig>;
    settings?: { isSecondServiceEnabled: boolean };
    textBlocks: TextBlock[];
    templateConfig: TemplateConfig;
    notificationsSettings?: {
        pushAppId?: string;
        safari_web_id?: string;
        pushOptions?: PushOptions;
    };
    fallbackSettings?: { domain: string; excludedBuyers: number[] } | null;
}

export interface PushOptions {
    promptOptions?: {
        text: {
            actionMessage: string;
            acceptButton: string;
            cancelButton: string;
        };
    };
    notifyButton?: {
        enable: boolean;
        size: "small" | "medium" | "large";
        position: "bottom-left" | "bottom-right";
        offset: {
            bottom: string;
            left: string;
            right: string;
        };
    };
}

export const getDomainInfo = async ({
    req,
    categorySlug,
}: {
    req: ServerRequest;
    categorySlug: string | undefined | null;
}): Promise<{ data: DomainInfo | null; error: AxiosError | null }> => {
    const accessToken = req.accessToken;

    const config: AxiosRequestConfig = {
        method: "get",
        url: `/api/web/v2/info?${
            categorySlug ? `categorySlug=${categorySlug}` : ""
        }`,
        headers: {
            "X-DOMAIN-NAME": `${req.headers?.host ?? ""}`,
            Authorization: `Bearer ${accessToken as string}`,
            "X-Language": req.locale ?? "en",
        },
    };
    const { data: domainInfoResponse, error } =
        await apiHandler<DomainInfoResponse>(() => HTTP.server(config));

    return {
        data: domainInfoResponse?.data ?? null,
        error,
    };
};
